import React from "react";
import { fb, validators } from '../../form'
import utils from "../../utils";

export function getFormDataStep1(data) {
  //const genderList = ['Female', 'Male'].map(x => { return { name: x, value: x } })

  const model = fb.group({
    firstName: [
      data.firstName || '',
      [validators.Required()],
      { label: 'First Name', type: 'text' },
    ],
    lastName: [
      data.lastName || '',
      [validators.Required()],
      { label: 'Last Name', type: 'text' },
    ],
    //gender: [data.gender || [], [validators.Required()], { type: 'radioList', options: genderList, style: { inline: false } }]
  })

  return model
}

export function getFormDataStep2(data) {
  const model = fb.group({
    email: [
      data.email || '',
      [validators.Required(), validators.Email()],
      { label: 'Email Address', type: 'email' },
    ],
    mobile: [
      data.mobile || '',
      [],
      { label: 'Mobile (optional)', type: 'text' },
    ],
  })

  return model
}

export function getFormDataStep3(data) {
  let stateList = utils.site.isNZ
    ? [
        { name: "Auckland", value: "NZ_AKL" },
        { name: "New Plymouth", value: "NZ_NPL" },
        { name: "Hawke's Bay", value: "NZ_HKB" },
        { name: "Wellington", value: "NZ_WGN" },
        { name: "Nelson", value: "NZ_NSN" },
        { name: "Marlborough", value: "NZ_MBH" },
        { name: "Westland", value: "NZ_WTC" },
        { name: "Canterbury", value: "NZ_CAN" },
        { name: "Otago", value: "NZ_OTA" },
        { name: "Southland", value: "NZ_STL" },
      ]
    : [
        { name: "NSW", value: "AU_NSW" },
        { name: "VIC", value: "AU_VIC" },
        { name: "QLD", value: "AU_QLD" },
        { name: "WA", value: "AU_WA" },
        { name: "SA", value: "AU_SA" },
        { name: "TAS", value: "AU_TAS" },
        { name: "ACT", value: "AU_ACT" },
        { name: "NT", value: "AU_NT" },
      ];
  stateList = stateList.sort((a, b) => a.name.localeCompare(b.name));
  stateList = [{ name: "Select...", value: "" }, ...stateList];
  const model = fb.group({
    dob: [
      data.dob || '',
      [validators.Required(), validators.PastDate('Please check the date.')],
      { label: 'When is your Birthday?', type: 'dob' },
    ],
    // postcode: [
    //   data.postcode || '',
    //   [validators.Required()],
    //   { label: 'Postcode ', type: 'text' },
    // ],
    postcode: [
      data.postcode || "",
      [validators.Required("Please select your State or Province.")],
      { label: "State / Province", type: "select", options: stateList },
    ],
  })

  return model
}

export function getFormDataStep4(data) {
  let hairTypeList = [
    'LONG',
    'MEDIUM',
    'SHORT',
    'FINE',
    'THICK',
    'STRAIGHT',
    'CURLY',
    'NATURAL',
    'FRIZZY',
    'COLOURED',
  ].map((x) => {
    return { name: x, value: x }
  })

  const model = fb.group({
    hairTypes: [
      data.hairTypes || '',
      [validators.Required('Please select at least one.')],
      {
        label: null,
        type: 'checkboxList',
        options: hairTypeList,
        style: { inline: true, className: 'listMin3' },
      },
    ],
  })

  return model
}

export function getFormDataStep5(data) {
  let interestList = [
    `Hair trends, hair hacks & tips`,
    `Promotions & offers`,
    `New product releases`,
  ].map((x) => {
    return { name: x, value: x }
  })

  const model = fb.group({
    //interests: [data.interests || '', [validators.Required('Please select at least one.')], { label: null, type: 'checkboxList', options: interestList, style: { inline: false, className: 'listMin3' } }]
    interests: [
      data.interests || [
        `Hair trends, hair hacks & tips`,
        `Promotions & offers`,
        `New product releases`,
      ],
      [validators.Required('Please select at least one.')],
      {
        label: null,
        type: 'checkboxList',
        options: interestList,
        style: { inline: false, className: 'listMin3' },
      },
    ],
  })

  return model
}
